<template>
  <app-header/>
  <Suspense>
    <template #default>
        <app-search :search="search"/>
    </template>
    <template #fallback>
        <Spinner/>
    </template>
  </Suspense>
  <app-footer/>
</template>

<script>
import { Suspense } from "vue";
import AppFooter from '../../components/layouts/AppFooter.vue'
import AppHeader from '../../components/layouts/AppHeader.vue'
import AppSearch from '../../components/Pages/AppSearch.vue'
import Spinner from '../../components/Spinners/Spinner.vue'
export default {
    name: 'Searches',
  props: {
    search: String
  },
  components: { AppHeader,AppFooter ,Spinner, AppSearch,Suspense },

}
</script>

<style>

</style>