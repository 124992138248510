<template>
    <div class="catalog">
        <div class="container">
            <div class="row">
                <div class="col-12">    
                    <div class="catalog__nav" dir="rtl">
                       <h3 style="width: 100%;text-align: right;color: #a8055f;">نتائج البحث عن : {{ this.$route.params.search }}</h3>
                    </div>
                    <div class="tab-content">
                       <div class="tabs__tab active" id="tab_1" data-tab-info>
                            <div class="row row--grid" style="margin-top: 5px;border-radius: 12px;padding: 5px;margin-right: 3px;margin-left: 3px;margin-bottom: 5vh;">
                                <div class="col-12 col-sm-4 col-lg-3 col-xl-4"  v-for="post in posts" :key="post.id">
                                    <div class="card">
                                        <a @click="getContent(post)" class="card__cover">
                                           <img v-lazy="post.img" alt="">
                                           <svgVideo />
                                        </a>
                                        <h3 class="card__title"><a @click="getContent(post)">{{ post.title_ar }}</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';


import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { useCookie } from 'vue-cookie-next'

export default {
    name: 'AppSearch',
    props: {
        search: String
    },
    async setup(props) {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie();
        const posts = ref([]);
        
        try {
            await HTTP.get(`GetSearchContent.php?search=${props.search}`).then((res) => {
                posts.value = res.data.Content; 
            });
        } catch (err) {
            console.log(err);
        }
        
        const getContent = (post) => {
            cookie.setCookie('cat_id', post.cat_id);
            if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status")) {
                router.push({ name: "Content", params: { content_id: post.id } });
            } else {
                cookie.setCookie('content_id', post.id);
                window.open("https://dsplp.sd.zain.com/?p=9619984619", "_blank");	
            }
        };

        

      return { posts, getContent };
    },
}
</script>

<style>

</style>